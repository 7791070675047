import { sentryRelease } from './build-args';

export const environment = {
  servers: [location.origin],

  sentry: {
    enable: true,
    dsn: 'https://2e5fdc1edb1b053fa4aa4baffa3c261a@sentry.anyong.com.tw/6',
    release: sentryRelease,
    browserTracing: {
      enable: true,
      tracePropagationTargets: [
        /^https:\/\/d-www\.gosu]\.party/,
        /^https:\/\/www\.gosu]\.party/,
      ],
    },
  },
};
