import { Injectable, computed, signal } from '@angular/core';
import { wsc } from '@ay-gosu-party/server-shared';
import { ExposedPromise } from '@ay/util/exposed-promise';
import { ReplaySubject, debounceTime, map } from 'rxjs';
import { environment } from '../environments/environment';

const CONNECTED = '連線至伺服器';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  public status$ = new ReplaySubject<string>(1);
  public status = signal('正在連線至伺服器');

  public connected$ = this.status$.pipe(map((_) => _ === CONNECTED));
  public isConnected = computed(() => this.status() === CONNECTED);
  public connected!: Promise<void>;

  public constructor() {
    let ep = new ExposedPromise<void>();
    this.connected = ep.promise;

    wsc.status.pipe(debounceTime(100)).subscribe((status) => {
      this.status$.next(status);
      this.status.set(status);

      // 如果連線成功，且有等待中的 promise，則 resolve
      if (status === CONNECTED) {
        if (ep.isPending()) {
          ep.resolve();
        }

        // 如果連線失敗， promise 已經被 resolved ，則建立一個新的 promise
      } else if (!ep.isPending()) {
        ep = new ExposedPromise<void>();
        this.connected = ep.promise;
      }
    });

    wsc.connect(...environment.servers);
  }
}
